@font-face {
  font-family: "PFHandbookPro-Black";
  src: url("../fonts/PFHandbookPro-Black.woff2") format("woff2"), url("../fonts/PFHandbookPro-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFCompactDisplay-Regular";
  src: url("../fonts/SFCompactDisplay-Regular.woff2") format("woff2"), url("../fonts/SFCompactDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
